import { IdentityKeys } from '../Identity'
import Logger from '../../helpers/Logger'

/**
 * Helper methods to check existing valid identity authentication data for
 * identity services. Also provides a place for identity services to set up
 * any functionality that existing authenticated data may require to function.
 */
export const checkExistingAuthData = {
  liveRamp(): boolean {
    const existingEnvelopeData = localStorage.getItem(IdentityKeys.liveRamp)
    if (existingEnvelopeData) {
      const { envelope, expiresAt } = JSON.parse(existingEnvelopeData)
      const notExpired = new Date(expiresAt) > new Date()
      if (notExpired) {
        // set up public API for ATS for third parties to consume envelope data
        window.ats = window.ats || {}
        window.ats.retrieveEnvelope = (callback: Function): void => {
          callback(JSON.stringify({ envelope }))
        }
        Logger.debug(['Identity', 'LiveRamp'], 'Using existing envelope data')
        return true
      }
    }
    return false
  }
}
