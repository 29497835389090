export const generateNumberWithPrecision = (
  min: number,
  max: number,
  precision: number
): number => roundNumberToPrecision(generateRandomNumber(min, max), precision)

export function generateRandomNumber(min: number, max: number): number {
  return Math.random() * (max - min) + min
}

export function roundNumberToPrecision(number, precision): number {
  const power = Math.pow(10, precision)

  return Math.floor(number * power) / power
}

/**
 * Formats numbers in an easy-to-read way for GAM GAM tracking.
 *
 * Example formats:
 * input  | output
 * '30.34'| 30.34
 * .03    | 0.03
 * 3      | 3.00
 * 34     | 34.00
 * 34.3   | 34.30
 * 340.3  | 340.30
 */
export function formatFloor(number: number | string | undefined): string {
  if (typeof number === 'string') {
    return formatFloor(parseFloat(number))
  }

  if (typeof number === 'undefined') {
    return '-1'
  }

  if (number > 2) {
    return '2+'
  }

  const toHundreds = 1
  const toPenny = 2
  const toString = number.toFixed(2)
  let [beforeDecimal, afterDecimal] = toString.split('.')

  beforeDecimal = beforeDecimal || ''
  afterDecimal = afterDecimal || ''

  while (beforeDecimal.length < toHundreds) {
    beforeDecimal = `0${beforeDecimal}`
  }

  while (afterDecimal.length < toPenny) {
    afterDecimal += '0'
  }

  return `${beforeDecimal}.${afterDecimal}`
}
