import {
  IBidRequest,
  IGumGumBidRequest,
  IIXBidRequest,
  IPubmaticBidRequest,
  ITripleliftBidRequest,
  IS2SBidRequest
} from '../../typings/IPrebid'
import { PENNY_PRECISION } from '../constants'
import { Flooring } from '../flooring/Flooring'
import { Bidder } from '../enums'
import { roundNumberToPrecision } from '../helpers/numbers'
import type WebModel from '../models/WebModel'
import { queryParamHas } from '../helpers/getQueryParam'

/** This class houses all prebid bid request changes
 * occurring client-side. To add params to a specific request,
 * append the bidder's BidRequest interface in prebid.d.ts.
 * Then, find the bidders adjustment func below and add the param.
 */
export class BidRequestAdjustments implements IBidRequestAdjustment {
  readonly floor: number
  readonly outStreamFloor: number
  constructor(
    readonly model: WebModel,
    readonly adUnitId: string,
    houseFloor?: number
  ) {
    // If there are bids in the AuctionHouse for this slot, we use the the highest CPM
    // for this slot as the floor.
    if (houseFloor) {
      this.floor = houseFloor
      this.outStreamFloor = Math.max(
        houseFloor,
        Flooring.getOutstreamFloor(model)
      )
    } else {
      // Get the standard floor, if there are no bids in the auction house.
      this.floor = Flooring.getDisplayFloor(model, adUnitId)
      this.outStreamFloor = Flooring.getOutstreamFloor(model)
    }
  }

  adjustBids(bids: Array<IBidRequest>): Array<IBidRequest> {
    return bids.map(this.adjust.bind(this))
  }

  adjust(bid: IBidRequest): IBidRequest {
    if (this[bid.bidder]) {
      const func: IBidRequestAdjustFunc = this[bid.bidder].bind(this)
      func(bid)
    }

    return bid
  }

  getDiscrepancy(bidder: Bidder): number {
    return (this.model.discrepancies && this.model.discrepancies[bidder]) || 1
  }

  [Bidder.uam](bid: IBidRequest): void {
    /* noop */
  }

  [Bidder.indexExchange](bid: IIXBidRequest): void {
    bid.params.bidFloor = this.calculateFloor(bid)
    bid.params.bidFloorCur = 'USD'
  }

  [Bidder.gumgum](bid: IGumGumBidRequest): void {
    bid.params.bidfloor = this.floor
  }

  [Bidder.google](bid: IBidRequest): void {
    /* noop */
  }
  [Bidder.pubmatic](bid: IPubmaticBidRequest): void {
    bid.params.kadfloor = this.calculateFloor(bid).toString()
  }

  [Bidder.triplelift](bid: ITripleliftBidRequest): void {
    bid.params.floor = this.calculateFloor(bid)
  }

  [Bidder.S2S](bid: IS2SBidRequest): void {
    bid.params.floor = this.calculateFloor(bid)
    bid.params.iabOptouts = this.model.iabOptouts

    if (queryParamHas('test', 'nativeS2S') && bid.isNative) {
      bid.params.floor = 0.01
    }
  }

  /**
   * Calculates the floor for a bid based of a bid's
   * bidder's discrepancies, and whether a bid is outstream or banner.
   * @param bid
   */
  calculateFloor(bid: IBidRequest): number {
    const discrepancy = this.getDiscrepancy(Bidder[bid.bidder])
    const floor = bid.isOutstream ? this.outStreamFloor : this.floor
    const bidFloor = roundNumberToPrecision(
      floor / discrepancy,
      PENNY_PRECISION
    )
    return bidFloor
  }
}

type IBidRequestAdjustment = { [key in Bidder]: IBidRequestAdjustFunc }

type IBidRequestAdjustFunc = (bid: IBidRequest) => void
