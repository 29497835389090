import type WebModel from '../models/WebModel'
import mvFetch from '../helpers/mvFetch'
import { Device } from '../helpers'

const REQUEST_TIMEOUT = 2000 //ms
const URL_TOO_LONG_LENGTH = 2000
export default class PageIdLookup {
  /** Store of looked-up URLs page_ids */
  static pageIds = {}
  /** URL of the lookup service */
  static lookupSrc: string = window.$mediavine.web.model.pageIdSrc

  static slug = window.$mediavine.web.model.slug

  static cleanPathsDict = {}

  static ignoredQueryParams: string[] = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'gclid',
    'dclid',
    'gclsrc',
    'fbclid',
    '_gl',
    'epik'
  ]

  constructor(private webModel: WebModel) {
    PageIdLookup.lookupSrc = webModel.pageIdSrc
    PageIdLookup.slug = webModel.slug

    let excludedQPs = webModel.ga_excluded_query
      ? webModel.ga_excluded_query.split(',')
      : []
    excludedQPs = excludedQPs.map((query) => query.trim())

    PageIdLookup.ignoredQueryParams = PageIdLookup.ignoredQueryParams.concat(
      excludedQPs
    )

    PageIdLookup.getPageId()
  }

  /**
   * Removes the protocol, hostnames, hash, and excluded query parameters from URLs.
   * https://user:pass@www.website.com:9090/path/to/stuff?stuff=thing#hash => /path/to/stuff/?stuff=thing
   * @param url
   */
  static getCleanPath(url: string): string {
    if (PageIdLookup.cleanPathsDict[url]) {
      return PageIdLookup.cleanPathsDict[url]
    }

    const aTag = document.createElement('a')
    aTag.href = url
    let pathname = aTag.pathname

    // IE11 is a piece of shit; doesn't have the leading '/'
    if (pathname[0] !== '/') {
      pathname = `/${pathname}`
    }

    try {
      // Remove forbidden query parameters
      const queryString = aTag.search.substr(1)
      const queryParams = queryString.split('&')
      const queryPairs = queryParams.map((chunk) => chunk.split('='))
      const goodPairs = queryPairs.filter(
        (pair) => PageIdLookup.ignoredQueryParams.indexOf(pair[0]) === -1
      )

      // stitch the QPs back together.
      let newQueryString = goodPairs.map((pair) => pair.join('=')).join('&')
      newQueryString = newQueryString ? `?${newQueryString}` : ''

      const cleanPath = `${pathname}${newQueryString}`
      PageIdLookup.cleanPathsDict[url] = cleanPath

      return cleanPath
    } catch (err) {
      return pathname
    }
  }

  /**
   * Lookups up the current url's page_id.
   */
  static async getPageId(): Promise<number> {
    // Don't even try if we're on someone's file system.
    const isOnFileSystem = window.location.protocol === 'file:'
    // Don't lookup page ID if the UserAgent is a bot.
    const isUserAgentBot = Device.isBot
    // Don't bother with excessively long URLs
    const isURLTooLong = window.location.href.length > URL_TOO_LONG_LENGTH

    if (isOnFileSystem || isUserAgentBot || isURLTooLong) {
      return -1
    }

    const currentUrl = window.location.href
    const lookupPath = PageIdLookup.getCleanPath(currentUrl)
    const pageId = PageIdLookup.pageIds[lookupPath]

    if (pageId !== undefined) {
      return pageId
    } else {
      const pageIdLookupUrl = `${PageIdLookup.lookupSrc}/pages?slug=${
        PageIdLookup.slug
      }&url=${encodeURIComponent(lookupPath)}`
      const pageIdPromise = mvFetch
        .fetchJson(pageIdLookupUrl, 'GET', null, addTimeout)
        .then(async (respJson: IPageIdResponse) => {
          // console.log(`pageId Looked up:`,respJson, lookupUrl)

          PageIdLookup.pageIds[lookupPath] = respJson.page_id
          return respJson.page_id
        })
        .catch((err) => {
          // If it fails, set page_id to -1
          return -1
        })

      // Add the promise to the store so subsequent requests won't make extra API calls.
      PageIdLookup.pageIds[lookupPath] = pageIdPromise
      return pageIdPromise
    }
  }
}
// Helper function that adds a timeout to the XMLHttpRequest
function addTimeout(req: XMLHttpRequest): void {
  req.timeout = REQUEST_TIMEOUT
}

interface IPageIdResponse {
  page_id: number
}
