import * as addApstag from '../../../assets/apstagInit'
import { IBidRequest, IPrebidAdapter } from '../../../typings/IPrebidAdapter'
import { USP } from '../../cmp/usp/USP'
import { Bidder } from '../../enums'
import type WebModel from '../../models/WebModel'
import { MediaType } from '../../enums/MediaType'
import { SupplyChain } from '../helpers/schain/SupplyChain'
import { Flooring } from '../../flooring/Flooring'

export const UAM_CODE = '38918095-8e45-4332-88bf-226b3514cb64'
export const AMAZON_CODE = '3229'
// eslint-disable-next-line no-magic-numbers
const DEFAULT_VIDEO_SIZE: AdSize = [640, 480]
enum PREBID_STATUS_CODES {
  GOOD = 1,
  NO_BID = 2
}

const USD = 'USD'
export default class AmazonAdapter implements IPrebidAdapter {
  static amazonTargeting: AmazonTargeting = {}
  readonly code = 'amazon'
  readonly supportedMediaTypes = [MediaType.banner, MediaType.video]
  readonly spec = this

  constructor(
    readonly model: WebModel,
    gdpr: IGDPR,
    private _window: Window = window
  ) {
    const pubID = model.uam ? UAM_CODE : AMAZON_CODE
    const schain = new SupplyChain(model)

    const initApstag = async () => {
      if (gdpr.enableGdpr) {
        const { TCF } = await import(
          /* webpackChunkName: "gdpr"*/ '../../cmp/tcf/TCF'
        )
        // Wait for GDPR CMP to be ready and then init
        gdpr.onReady(() => {
          this._window.apstag.init({
            pubID,
            gdpr: {
              enabled: true,
              consent: TCF.tcString
            },
            us_privacy: USP.uspString,
            schain
          })
        })
      } else {
        this._window.apstag.init({
          pubID,
          gdpr: {
            enabled: false
          },
          us_privacy: USP.uspString,
          schain
        })
      }
    }

    addApstag()
    initApstag()
  }

  getSpec() {
    return this
  }

  callBids(
    bidRequest: IBidRequest,
    addBidResponse: Function,
    done: Function
  ): void {
    const adunits: Array<IAmazonBidRequest> = []

    bidRequest.bids.forEach((bid) => {
      // Remove previous targeting for this ad unit
      delete AmazonAdapter.amazonTargeting[bid.adUnitCode]

      const mediaType = bid.mediaTypes.video
        ? MediaType.video
        : MediaType.banner

      if (mediaType === MediaType.video) {
        const floor = Math.floor(Flooring.getVideoFloor(this.model) * 100)
        // Add to array we're going to request
        adunits.push({
          adUnitCode: bid.adUnitCode,
          slotID: bid.params.slotId,
          sizes: [DEFAULT_VIDEO_SIZE],
          mediaType,
          floor: {
            /** Amazon docs: integer where 100 = $1.00 */
            value: isNaN(floor) ? 0 : floor,
            currency: USD
          }
        })
      } else {
        // Add to array we're going to request
        const floor = Math.floor(
          Flooring.getDisplayFloor(this.model, bid.adUnitCode) * 100
        )
        adunits.push({
          adUnitCode: bid.adUnitCode,
          slotID: bid.adUnitCode,
          sizes: [bid.sizes[0]],
          mediaType,
          floor: {
            /** Amazon docs: integer where 100 = $1.00 */
            value: isNaN(floor) ? 0 : floor,
            currency: USD
          }
        })
      }
    })

    this._window.apstag.fetchBids({ slots: adunits }, (amazonBids) => {
      amazonBids.forEach((amazonBid) => {
        // Look up the slotID in the adUnits list, so we can cet the adUnitCode for the bid.
        const adUnitCode =
          adunits.find((adunit) => adunit.slotID === amazonBid.slotID)
            ?.adUnitCode || amazonBid.slotID

        const adjustments =
          window.$mediavine.web.amazonMap?.[amazonBid.amznp]?.[
            amazonBid.amznbid
          ]

        AmazonAdapter.amazonTargeting[adUnitCode] = {
          amznbid: amazonBid.amznbid,
          // if u isnt present, but b is, they're the same value
          // and the server omitted to save bytes
          amznbidub: adjustments?.u || adjustments?.b || amazonBid.amznbid,
          amznadj: adjustments?.b || amazonBid.amznbid,
          amzniid: amazonBid.amzniid,
          amznsize: amazonBid.size,
          amznp: amazonBid.amznp
        }
      })

      // Return blank ads to trigger Prebid auction ending
      adunits.forEach((adunit) => {
        const bidObject = this._window.pbjs.createBid(
          PREBID_STATUS_CODES.NO_BID
        )
        bidObject.bidderCode = Bidder.uam
        bidObject.mediaType = adunit.mediaType as string
        addBidResponse(adunit.slotID, bidObject)
      })

      // We're done
      done()
    })
  }
}
type AmazonTargeting = {
  [adUnitCode: string]: {
    // original bid
    amznbid: string
    // discrep/bucketed bid (unshieled)
    amznbidub: string
    // discrep/shielded/bucketed bid, will light up line item
    amznadj: string
    amzniid: string
    amznsize: string
    amznp: string
  }
}

export interface IAmazonBidRequest {
  adUnitCode: string
  slotID: string
  sizes: AdSizes
  mediaType?: string
  floor?: {
    value: number
    currency: 'USD'
  }
}
