import { IBidderSettings, IBidResponse, IBid } from '../../typings/IPrebid'
import { Bidder } from '../enums'
import { AdServerTargetKey } from '../enums/AdServerTargetKey'
import { queryParamHas } from '../helpers/getQueryParam'
import Logger from '../helpers/Logger'
import { VideoBidder, s2sBidder } from '../enums/Bidder'
import { MediaType } from '../enums/MediaType'
import { bucketizeBid } from '../googletagFacade/helpers/targetingCalculators'
import {
  BANNER_LINE_ITEM_VERSION,
  NATIVE_LINE_ITEM_VERSION,
  OUTSTREAM_LINE_ITEM_VERSION,
  STICKY_OUTSTREAM_LINE_ITEM_VERSION
} from '../constants'
export const nativeAdPartners: Array<Bidder | VideoBidder> = [Bidder.triplelift]
export const nonSafeFramePartners: (Bidder | VideoBidder | s2sBidder)[] = [
  s2sBidder.yieldmo_s2s
]

export type DiscrepancyValues = { [bidder in Bidder]?: number }

export default class BidderSettings implements Partial<IBidderSettings> {
  standard = {
    adserverTargeting: [
      {
        key: AdServerTargetKey.hb_bidder,
        val: (bidResponse) =>
          bidResponse.s2sBidder
            ? `${bidResponse.s2sBidder}_s2s`
            : bidResponse.bidderCode
      },
      {
        key: AdServerTargetKey.hb_adid,
        val: (bidResponse) => bidResponse.adId
      },
      {
        key: AdServerTargetKey.hb_pb,
        val: (bidResponse: IBidResponse) => {
          return transformBidToBucket(
            applyBidShield(bidResponse, this.displayBidShield)
          )
        }
      },
      {
        key: AdServerTargetKey.hb_bid,
        val: (bidResponse) => Number(bidResponse.cpm).toFixed(2)
      },
      /** The unbucketized bid that has had bidShield applied.
       * Used for calculating UR / URP to avoid double bucketing
       **/
      {
        key: AdServerTargetKey.hb_bsbid,
        val: (bidResponse) => applyBidShield(bidResponse, this.displayBidShield)
      },
      {
        key: AdServerTargetKey.hb_safeframe,
        val: isSafeframe
      },
      {
        key: AdServerTargetKey.hb_s2sBidder,
        val: (bidResponse) => bidResponse.s2sBidder
      },
      {
        key: AdServerTargetKey.hb_liv,
        val: (bidResponse: IBidResponse) => {
          // Must check 'isStickyOutstream' before 'isOutstream'
          if (shouldUseStickyOutstreamLIV(bidResponse)) {
            return STICKY_OUTSTREAM_LINE_ITEM_VERSION
          }

          if (isOutstream(bidResponse)) {
            return OUTSTREAM_LINE_ITEM_VERSION
          }

          if (isNative(bidResponse)) {
            return NATIVE_LINE_ITEM_VERSION
          }

          return BANNER_LINE_ITEM_VERSION
        }
      },
      {
        key: AdServerTargetKey.hb_ub,
        val: (bidResponse: IBidResponse) => {
          return transformBidToBucket(bidResponse.cpm)
        }
      },
      {
        key: AdServerTargetKey.hb_size,
        val: (bidResponse: IBidResponse) => {
          return (
            bidResponse.size ||
            (bidResponse.width &&
              bidResponse.height &&
              `${bidResponse.width}x${bidResponse.height}`) ||
            `unknown`
          )
        }
      }
    ],
    bidCpmAdjustment: this.applyDiscrepancies.bind(this)
  }

  constructor(
    private discrepancies: DiscrepancyValues | null,
    private videoDiscrepancies: DiscrepancyValues | null,
    private outstreamDiscrepancies: DiscrepancyValues | null,
    private displayBidShield: DiscrepancyValues | null
  ) {}

  /**
   * Discrepancies occur in partner's bids versus the amount they actually pay out.
   * This happens for a number of reason that Erich can tell you about. We adjust incoming
   * bids with a discrepancy multiplier to account for these differences in attempt to
   * match a bid as close as possible to the expected actual amount that will be paid.
   * @param bidCpm
   * @param bidResponse
   */
  private applyDiscrepancies(
    bidCpm: number,
    bidResponse: IBidResponse
  ): number {
    const isBidOutstream = isOutstream(bidResponse)

    // For testing individual partners, we can override a bid with ?test={partner} query params.
    // This test is hit or miss though depending on whether the partner actually bid!
    const forcePubmaticOutstreamWin =
      isBidOutstream &&
      bidResponse.bidder === 'pubmatic' &&
      queryParamHas('test', 'outstream')
    if (
      (bidResponse.s2sBidder
        ? queryParamHas('test', bidResponse.s2sBidder)
        : queryParamHas('test', bidResponse.bidder)) ||
      forcePubmaticOutstreamWin
    ) {
      const forcedAuctionResults = 20.0
      Logger.debug(
        `Forcing ${bidResponse.s2sBidder || bidResponse.bidder} to win auction!`
      )

      return forcedAuctionResults
    }

    let discrepancies: DiscrepancyValues
    if (isBidOutstream) {
      discrepancies = this.outstreamDiscrepancies || {}
    } else if (bidResponse.mediaType === MediaType.video) {
      discrepancies = this.videoDiscrepancies || {}
    } else {
      discrepancies = this.discrepancies || {}
    }

    const adjustment = discrepancies[bidResponse.bidder] || 1
    return bidCpm * adjustment
  }
}

/**
 * GAM contains Line Items of of differing density as the numbers get higher. However, bids
 * come in at a higher precision than those. This function transforms the received bid
 * into one that will match against a Line Item in DFP.
 * @param bidResponse IBidResponse
 */
export function transformBidToBucket(cpm: number): string {
  cpm = Number(cpm)
  const newBid = bucketizeBid(cpm)

  return (Math.round(newBid * 100) / 100).toFixed(2)
}

export function applyBidShield(
  {
    cpm,
    bidderCode,
    s2sBidder,
    mediaType
  }: Pick<IBidResponse, 'cpm' | 'bidderCode' | 's2sBidder' | 'mediaType'>,
  displayBidShield: DiscrepancyValues | null
) {
  const bidder = s2sBidder || bidderCode
  if (!displayBidShield || !displayBidShield[bidder]) {
    return cpm
  }
  const bidShieldAdjustment = displayBidShield[bidder]

  return cpm * bidShieldAdjustment
}

/* 1 if safeframe, 0 if not */
export function isSafeframe(bidResponse: IBidResponse): '1' | '0' {
  if (isNative(bidResponse)) {
    return '1'
  }

  if (isTripleliftHDX(bidResponse)) {
    return '1'
  }

  if (
    isInBannerNativeAd(bidResponse) ||
    bidResponse.mediaType === MediaType.video ||
    nonSafeframePartner(bidResponse)
  ) {
    return '0'
  }

  return '1'
}

export function nonSafeframePartner(
  bidResponse: Pick<IBidResponse, 'bidderCode' | 's2sBidder'>
) {
  if (typeof bidResponse.s2sBidder === 'string') {
    return (
      nonSafeFramePartners.indexOf(
        `${bidResponse.s2sBidder}_s2s` as s2sBidder
      ) !== -1
    )
  }
  return nonSafeFramePartners.indexOf(bidResponse.bidderCode) !== -1
}

export function isInBannerNativeAd(bidResponse: IBidResponse): boolean {
  if (isOutstream(bidResponse)) {
    return false
  }

  return (
    (bidResponse.height === 1 && bidResponse.width === 1) ||
    bidResponse.size === '1x1' ||
    isNativePartnerAd(bidResponse) ||
    isGumGumInScreenOrInArticle(bidResponse)
  )
}

export function isNativePartnerAd({
  adUnitCode,
  bidderCode,
  s2sBidder
}: Pick<IBidResponse, 'adUnitCode' | 'bidderCode' | 's2sBidder'>) {
  return (
    (adUnitCode.indexOf('content_') !== -1 ||
      adUnitCode.indexOf('recipe') !== -1 ||
      adUnitCode.indexOf('sidebar') !== -1 ||
      adUnitCode.indexOf('feed_') !== -1) &&
    (nativeAdPartners.indexOf(bidderCode) !== -1 ||
      nativeAdPartners.indexOf(String(s2sBidder) as Bidder) !== -1)
  )
}

export function isTripleliftHDX(bidResponse: IBidResponse) {
  return (
    (bidResponse.bidderCode === Bidder.triplelift ||
      bidResponse.s2sBidder === Bidder.triplelift) &&
    bidResponse.mediaType !== MediaType.video &&
    bidResponse.ad &&
    bidResponse.ad.indexOf('ib.3lift.com') === -1
  )
}

export function isGumGumInScreenOrInArticle(
  bidResponse: IBidResponse
): boolean {
  return (
    (bidResponse.bidderCode === Bidder.gumgum ||
      bidResponse.s2sBidder === Bidder.gumgum) &&
    !!bidResponse.ad &&
    (bidResponse.ad.indexOf('product="2"') !== -1 || bidResponse.ext?.inarticle)
  )
}

export function isOutstream(bidResponse: IBidResponse): boolean {
  const adUnitCode = bidResponse.outstreamAdUnitCode || bidResponse.adUnitCode
  return (
    adUnitCode.indexOf('outstream') !== -1 &&
    bidResponse.mediaType === MediaType.video
  )
}

// UniversalPlayer and SidebarBTF are the only slots that should use the stickyOutstream line items
export function shouldUseStickyOutstreamLIV(
  bidResponse: IBidResponse
): boolean {
  const adUnitCode = bidResponse.outstreamAdUnitCode || bidResponse.adUnitCode

  if (bidResponse.unifyOutstream) {
    return false
  }

  return (
    adUnitCode === 'sidebar_btf_outstream_desktop' ||
    adUnitCode === 'universalPlayer_outstream_desktop' ||
    adUnitCode === 'universalPlayer_outstream_mobile' ||
    adUnitCode === 'adhesion_mobile_outstream_mobile' ||
    adUnitCode === 'adhesion_tablet_outstream_mobile'
  )
}

export function isNative(bidResponse: IBidResponse): boolean {
  return !!bidResponse.native
}

export function isInBannerVideo(bidResponse: IBidResponse): boolean {
  const adUnitCode = bidResponse.outstreamAdUnitCode || bidResponse.adUnitCode
  return (
    adUnitCode.indexOf('ibv') !== -1 &&
    bidResponse.mediaType === MediaType.banner
  )
}
