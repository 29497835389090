import { Device } from '../helpers/Device'
import type WebModel from '../models/WebModel'
import { MediaType } from '../enums/MediaType'
import { GeoLocation, IGeo } from '../helpers/geoLocation'

// We don't have line items in GAM below these minimum prices,
// so any bids below would be thrown away if they ever hit GAM
export const minimumDisplayFloor = 0.1
export const minimumVideoFloor = 2.0
export const minimumOutstreamFloor = 1.0
export const tallAdIndex = 1.5

export namespace Flooring {
  export const getDisplayFloor = (
    model: IWebViewModel | WebModel,
    adUnitId: string
  ) => {
    const floor = getFloorHelper(
      model,
      MediaType.banner,
      GeoLocation.geo,
      adUnitId
    )
    return Math.max(minimumDisplayFloor, floor)
  }

  export const getVideoFloor = (model: IWebViewModel | WebModel) => {
    const floor = getFloorHelper(model, MediaType.video, GeoLocation.geo)
    return Math.max(minimumVideoFloor, floor)
  }

  export const getOutstreamFloor = (model: IWebViewModel | WebModel) => {
    const floor = getFloorHelper(model, MediaType.outstream, GeoLocation.geo)
    return Math.max(minimumOutstreamFloor, floor)
  }

  export const getFloor = (
    model: IWebViewModel | WebModel,
    mediaType: MediaType,
    adUnitId?: string
  ) => {
    return getFloorHelper(model, mediaType, GeoLocation.geo, adUnitId)
  }
}

/** Ensure only results of this function are used by consuming agents */
type ComputedDevice = string & { flag: true }
function computeDevice(): ComputedDevice {
  if (Device.deviceType === 'desktop') {
    return Device.deviceType as ComputedDevice
  }

  const { deviceType, os } = Device
  const normalizedDevice = DeviceMap[deviceType]
  const normalizedOS = OSMap[os]

  return `${normalizedDevice} - ${normalizedOS}` as ComputedDevice
}

interface IGetIndexesArgs {
  device: ComputedDevice
  category: CategoryMap
  country: string
  mediaType: MediaType
  adUnitId: string
}

function getIndexes({
  device,
  category,
  country,
  mediaType,
  adUnitId
}: IGetIndexesArgs): {
  device: number
  country: number
  category: number
  mediaType: number
  adUnit: number
} {
  const defaultValue = 1.00001
  const categoryDefaultValue = 1.29
  const adUnitDefaultValue = 1
  const indexes = {
    device: DeviceIndexes[device] || defaultValue,
    country: CountryIndexes[country] || CountryIndexes.default,
    category: CategoryIndexes[category] || categoryDefaultValue,
    mediaType: MediaTypeIndexes[mediaType],
    adUnit: AdunitIndexes[adUnitId] || adUnitDefaultValue
  }

  return indexes
}

const baseIndex = 0.35

export function getFloorHelper(
  model: IWebViewModel | WebModel,
  mediaType: MediaType,
  geo: IGeo | undefined,
  adUnitId?: string
): number {
  const device = computeDevice()

  const country = (geo && geo.country_code) || 'other'
  const category =
    CategoryMap[model.topLevelCategory && model.topLevelCategory.slug] ||
    CategoryMap.None
  const adUnit = adUnitId || 'other'
  const indexes = getIndexes({
    device,
    country,
    category,
    mediaType,
    adUnitId: adUnit
  })

  const floor = Object.keys(indexes).reduce(
    (acc, key) => acc * indexes[key],
    baseIndex
  )

  const roundedFloor = parseFloat(floor.toFixed(2))

  return roundedFloor
}

export const AdunitIndexes = {
  interstitial_desktop: 4.0,
  interstitial_mobile: 4.0,
  adhesion_desktop: 1,
  adhesion_mobile: 1,
  adhesion_tablet: 1,
  comments_btf: 1,
  comments_mobile: 1,
  content_btf: 1,
  content_ibv_desktop: 1,
  content_ibv_mobile: 1,
  content_mobile: 1,
  feed_btf: 1,
  feed_mobile: 1,
  leaderboard_atf: 1,
  leaderboard_btf: 1,
  recipe_btf: 1,
  recipe_ibv_desktop: 1,
  recipe_ibv_mobile: 1,
  recipe_mobile: 1,
  sidebar_atf: 1,
  sidebar_btf: 1,
  other: 1
}

export const DeviceIndexes = {
  amp: 0.82,
  desktop: 1.47,
  other: 0.27,
  'phone - android': 1.63,
  'phone - ios': 0.72,
  'tablet - android': 1.72,
  'tablet - ios': 0.96
}

export const MediaTypeIndexes = {
  [MediaType.video]: 1.3,
  [MediaType.banner]: 0.4,
  [MediaType.outstream]: 1.07
}

export const CategoryIndexes = {
  None: 1.29,
  'Arts & Entertainment': 0.81,
  Education: 0.78,
  'Family & Parenting': 1.11,
  'Food & Drink': 1.28,
  'Health & Fitness': 1.02,
  'Hobbies & Interests': 1.0,
  'Home & Garden': 1.38,
  'Personal Finance': 1.38,
  Pets: 1.33,
  Politics: 0.63,
  'Style & Fashion': 1.17,
  Travel: 1.11
}

export enum OSMap {
  iOS = 'ios',
  Android = 'android',
  desktop = 'desktop'
}

export enum DeviceMap {
  mobile = 'phone',
  tablet = 'tablet',
  desktop = 'desktop'
}

/** Maps slugs to display values being used by Cynthia */
export enum CategoryMap {
  None = 'None',
  'arts-and-entertainment' = 'Arts & Entertainment',
  'education' = 'Education',
  'family-and-parenting' = 'Family & Parenting',
  'food-and-drink' = 'Food & Drink',
  'health-and-fitness' = 'Health & Fitness',
  'hobbies-and-interests' = 'Hobbies & Interests',
  'home-and-garden' = 'Home & Garden',
  'personal-finance' = 'Personal Finance',
  'pets' = 'Pets',
  'politics' = 'Politics',
  'style-and-fashion' = 'Style & Fashion',
  'travel' = 'Travel'
}

export const CountryIndexes = {
  US: 1.94,
  GB: 1.23,
  CA: 1.41,
  AU: 1.31,
  IN: 0.23,
  DE: 1.07,
  ZA: 0.73,
  SG: 1.21,
  PH: 0.29,
  NZ: 1.23,
  NL: 0.88,
  MY: 0.48,
  IE: 0.85,
  FR: 1.0,
  MX: 0.64,
  ES: 0.48,
  AE: 1.03,
  NG: 0.29,
  SE: 0.71,
  BE: 1.01,
  ID: 0.14,
  CH: 1.3,
  AT: 1.04,
  IT: 0.42,
  BR: 0.13,
  HK: 1.21,
  NO: 0.68,
  JP: 0.81,
  AR: 0.13,
  PK: 0.16,
  default: 0.24
}
