import {
  IAdServerTargeting,
  IBidResponse,
  IFilteredBidResponses,
  IBidsBackHandlerResponse,
  IAuctionResults
} from '../../typings/IPrebid'
import type WebModel from '../models/WebModel'
import type { Slot } from '../slots/Slot'
import PrebidFacade from './PrebidFacade'

/**
 * Returns a cache object of Slot AdSizes, available to all instances,
 * for improved performance when filtering by bidResponse width/height
 */
function cache() {
  // eslint-disable-next-line
  const cachedAdUnits = {} as { adUnitId: { string: boolean } }
  return (adUnitId: string, sizes: AdSizes) => {
    if (!cachedAdUnits[adUnitId]) {
      cachedAdUnits[adUnitId] = {}
      sizes.forEach((size) => {
        const dimension = Array.isArray(size) ? size.join('x') : size
        cachedAdUnits[adUnitId][dimension] = true
      })
    }
    return cachedAdUnits
  }
}

const adUnitCache = cache()

export class AuctionResults implements IAuctionResults {
  bidResponses: IFilteredBidResponses
  targeting: IAdServerTargeting | undefined
  hbCount: string

  constructor(
    model: WebModel,
    readonly slot: Slot,
    bidResponses?: IBidsBackHandlerResponse
  ) {
    this.bidResponses = this.filterBidResponses(
      this.getBidResponses(bidResponses)
    )
    this.targeting = PrebidFacade.getTargeting(slot.id) || {}
    this.hbCount = PrebidFacade.countBiddersAboveFloor(slot.id, model)
  }

  /**
   * Creates supplemental targeting data for analyzing
   * losing bids, etc.
   */
  getPrebidBidderTargeting(): { [key: string]: string | number } {
    // Set targetting info for each prebid bid.
    const bidsTargeting = {}
    this.bidResponses.forEach((prebid) => {
      if (prebid.cpm > 0) {
        const roundedBid = (Math.floor(prebid.cpm * 100) / 100).toFixed(2)

        // use s2sBidder if present, but trim that
        // bidder to max of 10 chars, to stay below
        // GAM 20 char limit
        const bidderCode = prebid.s2sBidder
          ? `hb_pb_${prebid.s2sBidder.substring(0, 10)}_s2s`
          : `hb_pb_${prebid.bidderCode}`

        bidsTargeting[bidderCode] = roundedBid
      }
    })

    return bidsTargeting
  }

  private getBidResponses(bidResponses?: IBidsBackHandlerResponse) {
    return (
      (bidResponses &&
        bidResponses[this.slot.id] &&
        bidResponses[this.slot.id].bids) ||
      []
    )
  }

  /**
   * Filters bid responses that are not applicable to a slot's current AdSizes
   * References a cache object of adUnitIds and sizes
   * Note: isOutstream bids are always let through.
   */
  private filterBidResponses(
    bidResponses: Array<IBidResponse>
  ): IFilteredBidResponses {
    if (bidResponses.length > 0) {
      const { adUnitId, sizes } = this.slot
      const adUnits = adUnitCache(adUnitId, sizes)
      return bidResponses.filter(({ width, height, isOutstream }) => {
        return isOutstream || adUnits[adUnitId][`${width}x${height}`]
      }) as IFilteredBidResponses
    }

    return bidResponses as IFilteredBidResponses
  }
}
