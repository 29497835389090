/**
 * GAM contains Line Items of of differing density as the numbers get higher. However, bids
 * come in at a higher precision than those. This function transforms the received bid
 * into one that will match against a Line Item in DFP.
 * @param bidCpm
 */
export function bucketizeBid(bidCpm: number): number {
  /* eslint-disable no-magic-numbers */
  const bid = Math.round(bidCpm * 100) / 100.0
  let bucketedBid = 0

  if (bid <= 0.1) {
    bucketedBid = 0.1
  } else if (bid < 1.11) {
    bucketedBid = 0.01 * Math.round(bid / 0.01)
  } else if (bid < 1.8) {
    bucketedBid = 0.03 * Math.round(bid / 0.03)
  } else if (bid < 3.0) {
    bucketedBid = 0.05 * Math.round(bid / 0.05)
  } else if (bid < 12.0) {
    bucketedBid = 0.15 * Math.round(bid / 0.15)
  } else if (bid < 20.0) {
    bucketedBid = 0.25 * Math.round(bid / 0.25)
  } else if (bid < 40.0) {
    bucketedBid = 1.0 * Math.round(bid / 1.0)
  } else if (bid < 70.0) {
    bucketedBid = 2.0 * Math.round(bid / 2.0)
  } else if (bid < 200.0) {
    bucketedBid = 5.0 * Math.round(bid / 5.0)
  } else {
    bucketedBid = 200
  }

  return bucketedBid
  /* eslint-enable no-magic-numbers */
}

export function calculateUrTargeting(bidCpm: number | string): string {
  const bidFloat = Number(bidCpm)
  const newBid = bucketizeBid(bidFloat)

  const ur = Math.round(newBid * 100)

  return ur.toString()
}

/**
 * Yes, URP (unified rule premium) is different from UR (unified rules)
 *
 * If we have a bid and it's above the outstream floor, we'll set a URP equal to that bid * 1.25
 * URP runs through our existing UR buckets
 * @param bidCpm
 */
export function calculateUrpTargeting(
  bidCpm: number | string,
  outstreamFloor: number
): string {
  const minimumUrp = 138
  const urpMultiplier = 1.25
  const bidFloat = Number(bidCpm) * urpMultiplier

  // If the bidFloat is below the outstreamFloor, use the floor instead.
  const newBid = bucketizeBid(Math.max(bidFloat, outstreamFloor))

  const ur = Math.round(newBid * 100)

  // If the value is below 185, we set it to 185. That's the lowest URP we have in the GAM rules.
  if (ur < minimumUrp) {
    return minimumUrp.toString()
  } else {
    return ur.toString()
  }
}
