import mvFetch from './mvFetch'

export class GeoLocation {
  static geo: IGeo | undefined
  static async retrieveGeo() {
    if (!GeoLocation.geo) {
      GeoLocation.geo = await getGeo()
    }
    return GeoLocation.geo
  }
}

const getGeo = async (): Promise<IGeo> => {
  const url = `https://scripts.mediavine.com/flooring/countryLookup` //`${model.launcherUrl}/flooring/countryLookup`
  let req
  try {
    req = await mvFetch.fetchJson(url)
  } catch (e) {
    return {
      country_code: 'US',
      city: 'unknown'
    }
  }

  return req.geo
}

export interface IGeo {
  city: string
  country_code: string
}
