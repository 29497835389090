export enum AdServerTargetKey {
  hb_advertiser = 'hb_advertiser',
  /** The bidder code. Used for logging and reporting to learn which bidder has higher fill rate/CPM. */
  hb_bidder = 'hb_bidder',
  /** The ad Id. Used by the ad server creative to render the ad (bidResponse.adId). */
  hb_adid = 'hb_adid',
  /** The price bucket. Used by the line item to target (bidResponse.pbMg). */
  hb_pb = 'hb_pb',
  /** The bidder from an S2S Bid response */
  hb_s2sBidder = 'hb_s2sBidder',
  /**
   * whether the ad is to be rendered within a safeframe
   * SafeFrame is a managed API-enabled iframe that opens a line of communication between the publisher page and the
   * iframe-contained ad creative. While an iframe restricts any activity between the ad and the page,
   * the communication protocol available with SafeFrame enables rich interaction
   * without risking page security.
   */
  hb_safeframe = 'hb_safeframe',
  /** the video line item version (OUTSTREAM_LINE_ITEM_VERSION || webModel.videoLineItemVersion) */
  hb_liv = 'hb_liv',
  /** used to compare bucketed hb_pb vs the unbucketed cpm  */
  hb_bid = 'hb_bid',
  /** The unbucketized bid that has had bidShield applied.
   * Used for calculating UR / URP to avoid double bucketing
   **/
  hb_bsbid = 'hb_bsbid',
  /**
   * The bucketized bid.
   * Because bids come in at a higher precision than our GAM LineItems.
   * We transform the received bid into one that will match against a Line Item in GAM.
   */
  hb_ub = 'hb_ub',
  /** The size of the ad truncated to 20 characters (bidResponse.size) */
  hb_size = 'hb_size',
  /** The number of valid bids for a slot expressed as a string (20 is the max). */
  hb_count = 'hb_count',
  /** whether the winning bid we pass in to GAM has a "deal_id" in the bid response */
  hb_pmp = 'hb_pmp',
  /** whether the winning bid came from a pool of cached bids or the current Auction */
  hb_pool = 'hb_pool',
  /** The amazon specific price bucket used by the line item to target. */
  hb_pb_amazon = 'hb_pb_amazon',
  /** The appnexus specific price bucket used by the line item to target. */
  hb_pb_appnexus = 'hb_pb_appnexus',
  hb_pb_appnexusAst = 'hb_pb_appnexusAst',
  /** The indexExchange specific price bucket used by the line item to target. */
  hb_pb_indexExchange = 'hb_pb_indexExchange',
  /** The rubicon specific price bucket used by the line item to target. */
  hb_pb_rubicon = 'hb_pb_rubicon',
  /** The triplelift specific price bucket used by the line item to target. */
  hb_pb_triplelift = 'hb_pb_triplelift'
}
