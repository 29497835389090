import * as loadjs from 'loadjs'
import type { AuctionHouse } from '../auctionHouse/AuctionHouse'
import {
  MAX_TARGETING_LENGTH,
  OUTSTREAM_FLOOR,
  OUTSTREAM_LINE_ITEM_VERSION
} from '../constants'
import { Bidder } from '../enums'
import { LoadJsBundle } from '../enums/LoadJsBundle'
import EventManager from '../eventManager/EventManager'
import { Cookie, Device } from '../helpers'
import { getQueryParam, queryParamHas } from '../helpers/getQueryParam'
import { formatFloor } from '../helpers/numbers'
import UTM from '../helpers/UTM'
import type WebModel from '../models/WebModel'
import AmazonAdapter from '../prebidFacade/amazonAdapter/AmazonAdapter'
import { AuctionResults } from '../prebidFacade/AuctionResults'
import { RecipeCardTracker } from '../recipeCardTracker/RecipeCardTracker'
import Session from '../session/Session'
import SlotRepository from '../slots/SlotRepository'
import type Keywords from '../keywords/Keywords'
import PageIdLookup from '../pageIdLookup/PageIdLookup'
import { USP } from '../cmp/usp/USP'
import {
  calculateUrTargeting,
  calculateUrpTargeting
} from './helpers/targetingCalculators'
import { LiftTest } from '../liftTest/LiftTest'
import GrowFaves from '../growFaves/GrowFaves'
import { Flooring } from '../flooring/Flooring'
import { Identity, GID } from '../identity/Identity'
import type { Slot } from '../slots/Slot'
import Logger from '../helpers/Logger'
import type { ISlotTargeting } from '../slots/Slot'

export const GOOGLETAG_URL =
  'https://securepubads.g.doubleclick.net/tag/js/gpt.js'
export const GOOGLETAG_BUNDLE = LoadJsBundle.googletag

export default class GoogletagFacade {
  private _pubads: googletag.PubAdsService
  private refreshTimeReachedMap: { [key: string]: boolean } = {}
  private completedAuctionResultsMap: {
    [key: string]: AuctionResults | undefined
  } = {}

  constructor(
    private model: WebModel,
    private auctionHouse: AuctionHouse,
    private keywordService: Keywords,
    private _window: Window = window
  ) {
    // Prime namespaces in case not loaded
    window.googletag = window.googletag || {}
    window.googletag.cmd = window.googletag.cmd || []

    if (isGoogletagLoaded()) {
      window.googletag.cmd.push(() => {
        loadjs.done(GOOGLETAG_BUNDLE)
      })
    } else {
      loadjs(GOOGLETAG_URL, GOOGLETAG_BUNDLE)
    }

    EventManager.on(
      EventManager.events.bidReady,
      this.handleBidReady.bind(this)
    )
    EventManager.on(
      EventManager.events.refreshTimeReached,
      this.handleRefreshTimeReached.bind(this)
    )

    GoogletagFacade.onReady<void>(() => {
      this.addEventListeners(auctionHouse)
      this.addPageLevelTargeting(model)

      if (Device.isMobileOrTablet) {
        this.pubads.setSafeFrameConfig({
          allowOverlayExpansion: false,
          allowPushExpansion: false,
          sandbox: true
        })
      } else {
        this.pubads.setSafeFrameConfig({
          allowOverlayExpansion: true,
          allowPushExpansion: true,
          sandbox: true
        })
      }

      this.pubads.collapseEmptyDivs()
      this.pubads.enableSingleRequest()
      // Disable initial load since we use refresh to display new ads
      this.pubads.disableInitialLoad()
      this.googletag.enableServices()
    })
  }

  /**
   * This will trigger a refresh on the slot without doing a new Prebid auction,
   * relying on what is already in the AuctionHouse
   * @param slot
   */
  static tryADifferentBidResponse(slot: Slot) {
    const auctionResultSet = [new AuctionResults(slot.model, slot, {})]
    // This function triggers GoogletagFacade to send a request to google.
    // It will take the the best bid from the AuctionHouse, but with empty
    // auctionResultsSet it will think that there were no prebid bids for this request
    GoogletagFacade.triggerRefreshedAdEvents(slot, auctionResultSet)
  }

  static triggerRefreshedAdEvents(
    slot: Slot,
    auctionResultSet: AuctionResults[]
  ) {
    slot.reauctions++
    EventManager.trigger(EventManager.events.bidReady, auctionResultSet)

    slot.refreshes++
    EventManager.trigger(EventManager.events.refreshTimeReached, slot)
  }

  static onReady<T>(cb: Function): Promise<T> {
    if (window.googletag && window.googletag.pubadsReady) {
      return Promise.resolve(cb())
    }

    // Anything returned by these cb's will be resolved after GPT is ready
    return new Promise((resolve, reject) => {
      loadjs.ready(GOOGLETAG_BUNDLE, {
        success: () => {
          window.googletag.cmd.push(() => resolve(cb()))
        },
        error: (deps) => reject(`Error while loading ${deps}`)
      })
    }).then((result) => result as T)
  }

  get googletag(): googletag.Googletag {
    return this._window.googletag
  }

  get pubads(): googletag.PubAdsService {
    this._pubads = this._pubads || this._window.googletag.pubads()

    return this._pubads
  }

  private async setSlotTargeting(
    auctionResults: AuctionResults
  ): Promise<AuctionResults> {
    const { slot } = auctionResults
    const amazonTargeting = AmazonAdapter.amazonTargeting[slot.id] || {}

    const { hbCount, highestBid, currentBidWon } =
      this.auctionHouse.getHighestBidData(slot, auctionResults)
    const targeting = (highestBid && highestBid.adserverTargeting) || undefined
    const targetingBid =
      targeting && targeting.hb_bsbid ? targeting.hb_bsbid : 0
    // cache for re-use if not the winner
    slot.highestPrebid = highestBid

    if (queryParamHas('test', 'nativeS2S') && targeting) {
      targeting['hb_pb'] = '10.05'
    }

    const allowGoogleToBid = this.shouldAllowGoogleToBid(slot)

    // Don't want PSA inside certain (UniversalPlayer) adunits, or when testSite=true
    const psas =
      this.model.psas && slot.psaEligible && !this.model.testSite
        ? this.model.psas.map((psa) => psa.gam_key)
        : []

    const playerGroup =
      window.MediavineVideo && window.MediavineVideo.playerGroup
    const pageId = await PageIdLookup.getPageId()
    const partnerLift = LiftTest.new(this.model).getPartnerLiftValue()
    const displayFloor = Flooring.getDisplayFloor(this.model, slot.adUnitId)
    const outstreamFloor = Flooring.getOutstreamFloor(this.model)
    const urValue = calculateUrTargeting(targetingBid || displayFloor)
    const urpValue = calculateUrpTargeting(targetingBid, outstreamFloor)

    slot.setTargeting({
      ...targeting,
      ...auctionResults.getPrebidBidderTargeting(),
      ...this.keywordService.getResults(),
      partnerLift,
      hb_bid: (targeting && targeting.hb_bid) || 'no_bid',
      slot_id: slot.id,
      hb_bidder:
        targeting && targeting.hb_bidder
          ? targeting.hb_bidder
          : `no_bidder_${currentBidWon ? '1' : '0'}`,
      hb_count: String(hbCount),
      hb_pool: currentBidWon ? '1' : '0',
      hb_pmp: highestBid && highestBid.dealId ? '1' : '0',
      UR: amazonTargeting.amznadj
        ? [urValue, amazonTargeting.amznadj]
        : urValue,
      URP: urpValue,
      OE:
        slot.type === 'Adhesion' ||
        slot.type === 'UniversalPlayer' ||
        slot.type === 'SidebarBtf'
          ? '0'
          : slot.meetsOutstreamCriteria()
          ? '1'
          : '0',
      google: allowGoogleToBid,
      native: allowGoogleToBid,
      slot_number: slot.number.toString(),
      slot: slot.adUnitId,
      arrival: slot.id === 'arrival' ? '1' : '0',
      refresh: slot.isRefreshing ? '1' : '0',
      refresh_count: slot.refreshes > 0 ? slot.refreshes : undefined,
      amznbid: amazonTargeting.amznbid,
      amznbidub: amazonTargeting.amznbidub,
      amznadj: amazonTargeting.amznadj,
      amzniid: amazonTargeting.amzniid,
      amznp: amazonTargeting.amznp,
      amznsize: amazonTargeting.amznsize,
      bidFloor: formatFloor(displayFloor),
      player_group: playerGroup,
      ccpa: USP.uspString,
      pid: pageId.toString(),
      psa: psas,
      timeout: slot.timeout.targetingCode,
      gid:
        (GrowFaves.hasLoggedInUser && GID.growAuth) ||
        (Identity.hasAuth.liveRamp && GID.identityApiAuth) ||
        GID.noAuth,
      inview: slot.onRail ? '1' : '0'
    })

    this.applyTargeting(slot)

    return auctionResults
  }

  applyTargeting(slot: Slot): Slot {
    const gSlot = slot.gSlot
    if (!gSlot) {
      Logger.debug(`gSlot is undefined on ${slot.id}`)
    } else {
      gSlot.clearTargeting()
      this.filterTargeting(slot)
      Object.keys(slot._targeting).forEach((key) => {
        const value = slot._targeting[key]
        if (value || value === 0) {
          gSlot.setTargeting(key, value)
        }
      })
    }

    // Clear targeting so weird stuff doesn't happen with refreshables
    slot._targeting = {}

    return slot
  }

  // Don't want google to bid on certain slots, or when testSite=true
  private shouldAllowGoogleToBid(slot: Slot): '0' | '1' {
    const googleIsEnabled = this.model.google
    const slotIsEligibleForGoogle = slot.adXEligible
    const placeholderModeIsOff = !queryParamHas('test', 'placeholders')
    const testSiteModeIsOff = !this.model.testSite

    const shouldBid =
      googleIsEnabled &&
      slotIsEligibleForGoogle &&
      placeholderModeIsOff &&
      testSiteModeIsOff

    return shouldBid ? '1' : '0'
  }

  private filterTargeting(slot: Slot): void {
    // set UR if no bids
    if (!slot._targeting.hb_pb) {
      slot._targeting.UR = calculateUrTargeting(
        Flooring.getDisplayFloor(slot.model, slot.adUnitId)
      )
    }

    // highest bid is below the floor.
    if (
      isBelowFloor(
        slot._targeting,
        Flooring.getDisplayFloor(slot.model, slot.adUnitId)
      ) ||
      isBelowOutstreamFloor(
        slot._targeting,
        Flooring.getOutstreamFloor(slot.model)
      )
    ) {
      slot._targeting.hb_adid = undefined
      slot._targeting.hb_bidder = undefined
      slot._targeting.hb_pb = undefined
      slot._targeting.UR = calculateUrTargeting(
        Flooring.getDisplayFloor(slot.model, slot.adUnitId)
      )
    }

    /* Ceiling velocity to 1000 */
    if (slot._targeting.velocity && slot._targeting.velocity > 1000) {
      slot._targeting.velocity = 1000
    }
  }

  private handleBidReady(auctionResultSet: Array<AuctionResults>): void {
    const refreshReadyAuctionResults: Array<AuctionResults> = []
    auctionResultSet.forEach((auctionResult) => {
      const slot = auctionResult.slot
      const slotId = slot.id
      const refreshTimeReached = this.refreshTimeReachedMap[slotId]

      if (refreshTimeReached || slot.reauctions === 0) {
        this.refreshTimeReachedMap[slotId] = false
        refreshReadyAuctionResults.push(auctionResult)
      } else {
        this.completedAuctionResultsMap[slotId] = auctionResult
      }
    })

    if (refreshReadyAuctionResults.length) {
      this.displayAds(refreshReadyAuctionResults)
    }
  }

  private handleRefreshTimeReached(slot: Slot): void {
    const slotId = slot.id
    const auctionResults = this.completedAuctionResultsMap[slotId]

    if (auctionResults) {
      this.completedAuctionResultsMap[slotId] = undefined
      this.displayAds([auctionResults])
    } else {
      this.refreshTimeReachedMap[slotId] = true
    }
  }

  private displayAds(auctionResultSet: Array<AuctionResults>): void {
    GoogletagFacade.onReady(async () => {
      const promises = auctionResultSet.map((bid) => this.setSlotTargeting(bid))
      const validAuctionResults = [] as AuctionResults[]

      ;(await Promise.all(promises)).forEach((resultsSet) => {
        const slot = resultsSet.slot
        EventManager.trigger(EventManager.events.slotRefreshed, slot)
        if (!slot.adXEligible && !slot.highestPrebid) {
          // If there were no prebid bids for a Slot where AdX is ineligible to bid, spoof
          // a SlotRenderEndedEvent so that things that rely on that (i.e.: Refreshables)
          // will still function even though we're not going to send a real request to GAM.
          const spoofSlotRenderEndedEvent =
            this.getSpoofEmptySlotRenderEndedEvent(slot)
          EventManager.trigger(
            EventManager.events.slotRenderEnded,
            spoofSlotRenderEndedEvent,
            slot
          )
        } else {
          validAuctionResults.push(resultsSet)
        }
      })

      // Get non-null gSlots
      const gSlots = validAuctionResults
        .map((bid) => bid.slot.gSlot)
        .filter((gSlot) => gSlot) as googletag.Slot[]

      if (gSlots.length > 0) {
        this.pubads.refresh(gSlots)
      }
    })
  }

  /**
   * Returns a spoof SlotRenderEndedEvent for the provided Slot.
   * The spoof event will be {isEmpty:true}, indicating that no bids were made.
   * @param slot
   */
  private getSpoofEmptySlotRenderEndedEvent(
    slot: Slot
  ): googletag.events.SlotRenderEndedEvent {
    return {
      isEmpty: true,
      size: '',
      slot: slot.gSlot as googletag.Slot,
      serviceName: 'publisher_ads'
    }
  }
  /**
   * Registers event listeners with GPT that will publish
   * through our own event manager.
   */
  private addEventListeners(auctionHouse: AuctionHouse): boolean {
    this.pubads.addEventListener(
      EventManager.events.slotRenderEnded,
      (e: googletag.events.SlotRenderEndedEvent) => {
        const slot = SlotRepository.getSlotByGSlot(e.slot)
        if (slot) {
          EventManager.trigger(EventManager.events.slotRenderEnded, e, slot)
        }
      }
    )

    return true
  }

  /**
   * Gets the Google Analytics session id from its cookie.
   */
  private getGaSessionId(): string {
    // This Cookie's value looks like this: GA1.2.1678643116.1586992502
    // The values are explained here: https://stackoverflow.com/a/16107194/2367620
    const gaCookieVal = new Cookie<string | null>({ name: '_ga' }).value || ''

    // After splitting the values on '.', the 3rd value is the one we want to use at the session id
    const gaSessionId = gaCookieVal.split('.')[2]
    return gaSessionId || ''
  }

  private addPageLevelTargeting(model: WebModel): boolean {
    const date = new Date()
    const recipeCardTracker = new RecipeCardTracker()

    this.pubads
      .setTargeting('site', model.slug.substr(0, MAX_TARGETING_LENGTH))
      .setTargeting(
        'path',
        window.location.pathname.substr(0, MAX_TARGETING_LENGTH)
      )
      .setTargeting('secure', window.location.protocol === 'https:' ? '1' : '0')
      .setTargeting('sessiondepth', Session.depth.toString())
      .setTargeting(
        'optout',
        model.optouts.map((optout) => optout.slug)
      )
      .setTargeting(
        'categories',
        model.categories.map((c) => c.slug.substr(0, MAX_TARGETING_LENGTH))
      )
      .setTargeting('generator', 'web')
      .setTargeting('bucket', (Math.floor(Math.random() * 100) + 1).toString())
      .setTargeting('referrer_url', Session.referrer)
      .setTargeting('utm_source', UTM.utmSource || '')
      .setTargeting('utm_campaign', UTM.utmCampaign || '')
      // Day of Week (0-6)
      .setTargeting('dow', date.getUTCDay().toString())
      // Day of Month (1-31)
      .setTargeting('day', date.getUTCDate().toString())
      // Month (1-12)
      .setTargeting('month', String(date.getUTCMonth() + 1))
      // Hour (0-23)
      .setTargeting('hour', date.getUTCHours().toString())
      .setTargeting('wrapper_group', model.versionGroup.name || '')
      .setTargeting('wswy', model.wswy)
      .setTargeting('sessionId', this.getGaSessionId())

    if (recipeCardTracker.recipeCard) {
      this.pubads.setTargeting('rcard', recipeCardTracker.recipeCard)
    }

    Object.keys(model.customTargeting).forEach((k) =>
      this.pubads.setTargeting(k, model.customTargeting[k])
    )

    let testParams = getQueryParam('test')
    // Convert testParams to an array if it's not already
    testParams = testParams
      ? Array.isArray(testParams)
        ? testParams
        : [testParams]
      : []

    const placeholdersIndex = testParams.indexOf('placeholders')
    // Force all ads to be placeholder ads.
    if (placeholdersIndex !== -1 || model.testSite) {
      // Convert 'placeholders' to 'houseads' if it exists, else push 'houseads'
      if (placeholdersIndex !== -1) {
        testParams[placeholdersIndex] = 'houseads'
      } else {
        testParams.push('houseads')
      }
    }
    if (testParams.length > 0) {
      this.pubads.setTargeting('test', testParams)
    }

    return true
  }
}

function isGoogletagLoaded(): boolean {
  const hasScript = !!document.querySelector(`script[src$="${GOOGLETAG_URL}"]`)

  // getVersion is one of the first keys added to googletag when gpt.js is loading.
  // Checking for this should tell us if googletag has already been loaded even if the GOOGLETAG_URL
  // wasn't used.
  const seemsLikeItsLoading = window.googletag.getVersion !== undefined

  return hasScript || seemsLikeItsLoading
}

export function isBelowOutstreamFloor(
  { hb_bid, hb_bidder, hb_liv }: ISlotTargeting,
  floor: number = OUTSTREAM_FLOOR
): boolean {
  // Check if this is even an outstream bid
  if (!hb_bidder || hb_liv !== OUTSTREAM_LINE_ITEM_VERSION) {
    return false
  }

  return isBelowFloor({ hb_bid }, floor)
}

export function isBelowFloor(
  { hb_bid }: ISlotTargeting,
  floor: number
): boolean {
  if (!hb_bid) {
    return false
  }

  return parseFloat(hb_bid) < floor
}

declare global {
  namespace googletag {
    /* eslint-disable-next-line  */
    interface Googletag {
      display(slot?: Element | googletag.Slot): void
      enums: {
        OutOfPageFormat: {
          BOTTOM_ANCHOR: number
          INTERSTITIAL: number
          REWARDED: number
          TOP_ANCHOR: number
        }
      }
    }
    /* eslint-disable-next-line  */
    interface Slot {
      getAdUnitPath(): string
      getClickUrl(): string
    }
    namespace events {
      interface ImpressionViewableEvent {
        size: Array<number>
      }
      /* eslint-disable-next-line  */
      interface SlotOnloadEvent {
        size: Array<number>
      }
      /* eslint-disable-next-line  */
      interface SlotVisibilityChangedEvent {
        size: Array<number>
      }
    }
  }
}
