import { IPrebidConfig } from '../../typings/IPrebid'
import { BidderSequence } from '../enums/BidderSequence'
import type WebModel from '../models/WebModel'
import GrowFaves from '../growFaves/GrowFaves'
import { Bidder } from '../enums'
import { SupplyChain } from './helpers/schain/SupplyChain'

const id5PartnerId = 719
const id5TimeoutHours = 8

/**
 * Further documentation for Prebid's config can be found at:
 * http://prebid.org/dev-docs/publisher-api-reference.html#module_pbjs.setConfig
 */
export class PrebidConfig implements IPrebidConfig {
  /** Bid requesting is synchronous.
   *  Here we tell Prebid to request each bidder
   * in the exact order of the bid requests given.
   * The alternative is random.
   *
   * Through trial and error, we've found our current
   * order of bidders provides the most lift. The order
   * can be found here:
   * https://github.com/mediavine/web-launcher-service/blob/master/src/adDefinitions/index.ts#L33
   */
  bidderSequence = BidderSequence.fixed

  /** If enabled, Prebid will create data from all bids
   *  in the ad server targeting. We prefer to only get
   *  data from the highest bid. However, we DO collect
   *  all bids in the AuctionHouse, which allows us to get data
   *  on each one, if desired.
   */

  enableSendAllBids = false

  /**
   * This option sets the limit of how many requests an adapter
   * can make to a single origin. Most browsers will allow at least 6
   * requests to a single origin before blocking them. However, too many
   * requests can have a negative affect on performance.
   */
  /* eslint-disable-next-line no-magic-numbers */
  maxRequestsPerOrigin = 6

  /**
   * PrebidConfig can be utilized by individual adapters.
   * This is a custom configuration for the Index Exchange adapter.
   */
  ix = {
    timeout: 1000
  }
  /**
   * A user sync is when an adapter performs some behavior
   * to gather information about the current client. This is
   * usually done through dropping pixels or scripts on the page.
   * These scripts help produce targeting information about the user,
   * which in turn, can increase the value of impressions on the page.
   *
   * These settings control how often an adapter can perform these
   * syncs. Too many can create security risks or affect page performance.
   * Too few can reduce the RPM of a page.
   *
   */

  cache = {
    url: 'https://prebid.adnxs.com/pbc/v1/cache'
  }

  userSync = {
    auctionDelay: 100,
    syncEnabled: true,
    syncsPerBidder: 3,
    enableOverride: GrowFaves.willGrowLoad() ? true : false,
    filterSettings: {
      all: {
        bidders: '*' as const, // '*' means all bidders
        filter: 'include' as const
      }
    },
    userIds: [
      {
        name: 'unifiedId',
        params: {
          partner: 'prebid',
          url: '//match.adsrvr.org/track/rid?ttd_pid=sne7dew&fmt=json'
        },
        storage: {
          type: 'html5',
          name: '_unifiedId',
          expires: 60
        }
      },
      {
        name: 'pubCommonId',
        storage: {
          type: 'cookie',
          name: '_pubCommonId', // create a cookie with this name
          expires: 1825 // expires in 5 years
        }
      },
      {
        name: 'identityLink',
        params: {
          pid: '2'
        },
        storage: {
          type: 'html5',
          name: 'idl_env', // set localstorage with this name
          expires: 30
        }
      },
      {
        name: 'merkleId',
        params: {
          vendor: 'idsv2',
          sv_cid: '5344_04531',
          sv_pubid: '17404',
          sv_domain: 'mediavine.com'
        },
        storage: {
          type: 'html5',
          name: 'merkle_id', // set localstorage with this name
          expires: 7
        }
      },
      {
        name: 'sharedId',
        params: {
          syncTime: 300 // in seconds, default is 24 hours
        },
        storage: {
          name: 'sharedid',
          type: 'html5',
          expires: 28
        }
      },
      {
        name: 'id5Id',
        params: {
          partner: id5PartnerId as unknown as string
        },
        storage: {
          type: 'html5',
          name: 'id5id',
          expires: 90,
          refreshInSeconds: id5TimeoutHours * 60 * 60
        }
      },
      {
        name: 'bidswitchId',
        storage: {
          type: 'cookie',
          name: 'bsw_id',
          expires: 30
        }
      },
      {
        name: 'flocId',
        params: {
          token:
            'A+i6TsQqPQNz8R2D7tYXLwxex3Q7LMZV14yKJ/5h8yuaacFWx6WjJrCrvPBpvf6xJqdk32ADu3xrdOWMushXMw8AAAB5eyJvcmlnaW4iOiJodHRwczovL2dyb3cubWU6NDQzIiwiZmVhdHVyZSI6IkludGVyZXN0Q29ob3J0QVBJIiwiZXhwaXJ5IjoxNjI2MjIwNzk5LCJpc1N1YmRvbWFpbiI6dHJ1ZSwiaXNUaGlyZFBhcnR5Ijp0cnVlfQ=='
        }
      }
    ]
  }

  /**
   * Prebid provides a built-in solution for GDPR and USP consent.
   * If we detect that consent is required, we enable this
   * option. Note that this is only the interface. It is added
   * within the constructor (conditionally for GDPR).
   */

  consentManagement: {
    gdpr?: {
      cmpApi: 'iab'
      timeout: number
      allowAuctionWithoutConsent: boolean
    }
    usp: {
      cmpApi: 'iab'
      timeout: number
    }
  }

  /**
   * schain module is optionally added in the constructor if a
   * site is not owned/operated by Mediavine, passing the site.id
   * value from the model to an object within the nodes array
   * http://prebid.org/dev-docs/modules/schain.html
   * https://github.com/InteractiveAdvertisingBureau/openrtb/blob/master/supplychainobject.md
   */

  schain: {
    validation: string
    config: {
      ver: string
      complete: number
      nodes: Array<{
        asi: string
        sid: string
        hp: number
      }>
    }
  }

  auctionOptions = {
    secondaryBidders: [Bidder.uam]
  }

  constructor(model: WebModel, gdpr: IGDPR) {
    if (model?.geoLoc?.city === 'BR') {
      this.userSync.syncEnabled = false
    }

    // TODO: refactor to a simple class
    this.consentManagement = {
      usp: {
        cmpApi: 'iab',
        timeout: 0
      }
    }

    // TODO: refactor to a simple class
    if (gdpr.enableGdpr) {
      this.consentManagement = {
        ...this.consentManagement,
        gdpr: {
          cmpApi: 'iab',
          timeout: 8000,
          allowAuctionWithoutConsent: true
        }
      }
    }

    // TODO: refactor to a simple class
    this.schain = {
      validation: 'strict',
      config: new SupplyChain(model)
    }

    this.cache.url = model.videoCacheUrl || this.cache.url
  }
}
