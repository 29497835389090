import { checkExistingAuthData } from './authHelpers/authHelpers'
import Logger from '../helpers/Logger'
import WebModel from '../models/WebModel'

export const IDENTITY_READY_EVENT = 'mediavineIdentityReady'

/** Public functionality for a user to be opted-in or opted-out of identity services */
export class Identity {
  /** Map of identity services where a user has valid authentication data */
  public static hasAuth = {} as IdentityStatus

  /** Publicly exposes optIn/optOut functions and fires the 'identityReady' event */
  public static init(model: WebModel): void {
    Identity.checkExistingAuthData()
    const optIn = Identity.optIn.bind(Identity.optIn, model)
    const optOut = Identity.optOut.bind(Identity.optOut, model)
    window.$mediavine.web.identityOptIn = optIn
    window.$mediavine.web.identityOptOut = optOut
    window.dispatchEvent(new CustomEvent(IDENTITY_READY_EVENT))
  }

  /** Bound to window.$mediavine.web.identityOptIn, opts a user in to various identity services */
  private static async optIn(
    model: WebModel,
    data: any,
    callback: IdentityCallback
  ): Promise<void> {
    try {
      // load chunked identity modules
      const [{ LiveRamp }] = await Identity.loadModules()
      // opt users in to identity services
      const identityOptInStatus: IdentityStatus = {
        liveRamp: await LiveRamp.optIn(model, data)
      }
      Identity.authServicesMap = identityOptInStatus
      // done
      return callback(null)
    } catch (error) {
      Logger.debug(['Identity', 'optIn'], error)
      return callback(error)
    }
  }

  /** Bound to window.$mediavine.web.identityOptOut, opts a user out of various identity services */
  private static async optOut(
    model: WebModel,
    // TODO: might possibly want a 'data' parameter from pubs in the future
    // data: any,
    callback: IdentityCallback
  ): Promise<void> {
    try {
      const [{ LiveRamp }] = await Identity.loadModules()
      const identityOptOutStatus: IdentityStatus = {
        liveRamp: LiveRamp.optOut()
      }
      Identity.authServicesMap = identityOptOutStatus
      return callback(null)
    } catch (error) {
      Logger.debug(['Identity', 'optOut'], error)
      callback(error)
    }
  }

  /** Dynamically imports related identity modules */
  private static async loadModules() {
    return Promise.all([
      import(/* webpackChunkName: "identity"*/ './liveRamp/LiveRamp')
    ])
  }

  /** Map existing client-side data to boolean values to indicate what's been successfully authenticated in the past */
  private static checkExistingAuthData(): void {
    Object.keys(IdentityKeys).map((service) => {
      const authHelperFunc = checkExistingAuthData[service]
      if (authHelperFunc) {
        Identity.hasAuth[service] = authHelperFunc()
      }
    })
  }

  /** Sets a map of identity services authentication statuses */
  private static set authServicesMap(identityStatus: IdentityStatus) {
    Identity.hasAuth = { ...Identity.hasAuth, ...identityStatus }
    Object.keys(identityStatus).forEach((identityService) => {
      Logger.debug(
        ['Identity'],
        `Authentication data set for: "${identityService}"`
      )
    })
  }
}

/** Possible GID values for GAM targeting */
export enum GID {
  noAuth = '0',
  growAuth = '1',
  identityApiAuth = '2'
}

/** Represents keys/names of identity service data stored client-side */
export enum IdentityKeys {
  liveRamp = 'mv_liveRamp'
}

export interface IdentityStatus {
  liveRamp: boolean
}

export interface IdentityCallback {
  (error: Error | null): void
}
