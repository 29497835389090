import { Cookie } from './cookie'
import { getQueryParam } from './getQueryParam'

const UTM_SOURCE = 'utm_source'
const UTM_CAMPAIGN = 'utm_campaign'

/**
 * Urchin Tracking Modules
 * UTM is a tracking system used by Google Analytics
 * This class sources the codes out of a URL
 * and preserves them across page navigation.
 */

export class UTM {
  utmSource?: string
  utmCampaign?: string
  private utmSourceCookie?: Cookie<string>
  private utmCampaignCookie?: Cookie<string>

  constructor(private _document: Document = document) {
    /** Skip our UTM cookies if GDPR enabled */
    if (window.__cmp || window.__tcfapi) {
      return
    }
    // use the query param or the cookie value
    this.utmSourceCookie = new Cookie<string>({ name: UTM_SOURCE, maxAge: 0 })
    this.utmCampaignCookie = new Cookie<string>({
      name: UTM_CAMPAIGN,
      maxAge: 0
    })
    this.utmSource =
      (getQueryParam(UTM_SOURCE, this._document.location.search) as string) ||
      this.utmSourceCookie.value

    // save whatever we got, possibly redunantly
    this.utmSourceCookie.set(this.utmSource)

    // same for UtmCampaign
    this.utmCampaign =
      (getQueryParam(UTM_CAMPAIGN, this._document.location.search) as string) ||
      this.utmCampaignCookie.value
    this.utmCampaignCookie.set(this.utmCampaign)
  }
}

export default new UTM()
