import {
  IAdServerTargeting,
  IBidResponse,
  IBidsBackHandler,
  IBidsBackHandlerResponse,
  IPrebid,
  IPrebidAdUnit
} from '../../typings/IPrebid'
import { IBidRequest, IPrebidAdapter } from '../../typings/IPrebidAdapter'
import type { AuctionHouse } from '../auctionHouse/AuctionHouse'
import EventManager from '../eventManager/EventManager'
import { doOnce } from '../helpers/doOnce'
import { queryParamHas } from '../helpers/getQueryParam'
import type WebModel from '../models/WebModel'
import type { Slot } from '../slots/Slot'
import AmazonAdapter from './amazonAdapter/AmazonAdapter'
import BidderSettings from './BidderSettings'
import { BidRequestAdjustments } from './BidRequestAdjustments'
import { PrebidConfig } from './PrebidConfig'
import { AuctionResults } from './AuctionResults'
import { MediaType } from '../enums/MediaType'
import { TimeoutValue } from '../slots/helpers/Timeout'
import { Flooring } from '../flooring/Flooring'
import SlotRepository from '../slots/SlotRepository'

export default class PrebidFacade {
  constructor(
    readonly model: WebModel,
    readonly gdpr: IGDPR,
    private auctionHouse: AuctionHouse,
    private pbjs = window.pbjs
  ) {
    this.aliasBidders(pbjs)
    this.registerAdapters(model, gdpr)
    this.addEventListeners(pbjs)
    this.initBidTie()
    pbjs.setConfig(new PrebidConfig(model, gdpr))
    pbjs.bidderSettings = new BidderSettings(
      model.discrepancies,
      model.videoDiscrepancies,
      model.outstreamDiscrepancies,
      model.displayBidShield
    )
  }

  static async loadPrebidImports() {
    const { default: prebid } = await import(
      /* webpackChunkName: "prebid" */ 'prebid.js'
    )

    return Promise.all([
      import(
        /* webpackChunkName: "prebid"*/ 'prebid.js/modules/consentManagement'
      ),
      import(
        /* webpackChunkName: "prebid"*/ 'prebid.js/modules/consentManagementUsp'
      ),
      import(/* webpackChunkName: "prebid"*/ 'prebid.js/modules/ixBidAdapter'),
      import(
        /* webpackChunkName: "prebid"*/ 'prebid.js/modules/pubmaticBidAdapter'
      ),
      import(
        /* webpackChunkName: "prebid"*/ 'prebid.js/modules/tripleliftBidAdapter'
      ),
      import(/* webpackChunkName: "prebid"*/ './s2sAdapter/S2SAdapter'),
      import(/* webpackChunkName: "prebid"*/ 'prebid.js/modules/userId'),
      import(
        /* webpackChunkName: "prebid"*/ 'prebid.js/modules/identityLinkIdSystem'
      ),
      import(
        /* webpackChunkName: "prebid"*/ 'prebid.js/modules/unifiedIdSystem'
      ),
      import(
        /* webpackChunkName: "prebid"*/ 'prebid.js/modules/merkleIdSystem'
      ),
      import(/* webpackChunkName: "prebid"*/ 'prebid.js/modules/flocIdSystem'),
      import(/* webpackChunkName: "prebid"*/ 'prebid.js/modules/schain'),
      import(
        /* webpackChunkName: "prebid"*/ 'prebid.js/modules/sharedIdSystem'
      ),
      import(/* webpackChunkName: "prebid"*/ 'prebid.js/modules/id5IdSystem'),
      import(
        /* webpackChunkName: "prebid"*/ './mediagridSync/bidswitchIdSystem'
      )
    ]).then(() => {
      prebid.processQueue()
    })
  }

  /**
   * Given an array of slots, this will request bids for each adunit in one request.
   * @param slots - Array of Slots
   */
  async requestBids(slots: Array<Slot>, timeout: TimeoutValue): Promise<void> {
    /**
     * Prebid requests bids from our partners here. When the auction
     * is complete, the bidBackHandler is fired.
     */
    return new Promise<void>((resolve, reject) => {
      if (slots.length === 0) {
        return resolve()
      }

      slots.forEach((slot) =>
        EventManager.trigger(EventManager.events.slotBidRequested, slot)
      )

      // Skip requesting bids if ?test=placeholders
      if (queryParamHas('test', 'placeholders') || this.model.testSite) {
        return resolve(this.emitBidReadyEvent(slots, {}))
      }

      /**
       * This function will be called when either the the auction ends,
       * or our custom timeout has elapsed.  The content of the function
       * will only be run once per auction.
       */
      const bidsBackHandler: IBidsBackHandler = doOnce(
        (bids: IBidsBackHandlerResponse) => {
          resolve(this.emitBidReadyEvent(slots, bids))
        }
      )

      const adUnits = this.getAdUnits(slots).then((adUnits) => {
        const extraTime = 500 // Used to test extending the timeout
        const bidRequests = this.pbjs.requestBids({
          adUnits,
          auctionId: MediaType.banner,
          timeout: timeout + extraTime,
          bidsBackHandler
        })
      })
    })
  }

  /**
   * Retrieves a copy of IPrebidAdUnits for each slot.
   * Modifies those adunits as necessary (add floors, etc)
   * @param slots - Array<Slot>
   */
  private async getAdUnits(slots: Array<Slot>): Promise<Array<IPrebidAdUnit>> {
    const allAdUnits: Array<IPrebidAdUnit> = []
    const mapPromise = slots.map(async (slot) => {
      const adUnits = await slot.adunits()
      adUnits.forEach((unit) => {
        unit.ortb2Imp = {
          ext: {
            data: {
              pbadslot: slot.adUnitPath || '',
              adserver: {
                name: 'gam',
                adslot: slot.adUnitPath || ''
              }
            }
          }
        }
      })

      const validAdUnits = adUnits.map((adunit) =>
        removeInvalidlySizedPrebids(adunit, slot)
      )
      const bidRequestAdjustments = new BidRequestAdjustments(
        this.model,
        slot.adUnitId,
        this.auctionHouse.getHighestBidForSlot(slot)?.cpm
      )
      validAdUnits.forEach((adUnit) => {
        adUnit.bids = bidRequestAdjustments.adjustBids(adUnit.bids)
        allAdUnits.push(adUnit)
      })
    })
    await Promise.all(mapPromise)
    return allAdUnits
  }

  static countBiddersAboveFloor(alias: string, model: WebModel): string {
    const bids: [IBidResponse] =
      window.pbjs.getBidResponsesForAdUnitCode(alias).bids
    /** Increment hb_count for all bidders above floor */
    let hbCount = 0
    for (const bid of bids) {
      const mediaType = bid.isOutstream ? 'outstream' : bid.mediaType
      if (
        bid.cpm >=
        Flooring.getFloor(model, MediaType[mediaType], bid.adUnitCode)
      ) {
        hbCount++
      }
    }

    return hbCount.toString()
  }

  private emitBidReadyEvent(
    slots: Array<Slot>,
    bidResponses?: IBidsBackHandlerResponse
  ): void {
    const auctionResultSet = slots.map(
      (slot) => new AuctionResults(this.model, slot, bidResponses)
    )
    EventManager.trigger(EventManager.events.bidReady, auctionResultSet)
  }

  private registerAdapter(adapter: IPrebidAdapter): void {
    this.pbjs.registerBidAdapter(() => adapter, adapter.code)
  }

  /**
   * Registers prebid's emitted events to publish across our own
   * EventManager. Registering them with our own EventManager helps
   * reduce the number of dependencies on PrebidFacade by other modules.
   */
  private addEventListeners(pbjs: IPrebid): boolean {
    pbjs.onEvent(EventManager.events.bidRequested, (e: IBidRequest) => {
      EventManager.trigger(EventManager.events.bidRequested, e)
    })

    pbjs.onEvent(EventManager.events.bidResponse, (e: IBidResponse) => {
      const slot = SlotRepository.getSlotById(e.adUnitCode)
      EventManager.trigger(EventManager.events.bidResponse, e, slot)
    })

    pbjs.onEvent(EventManager.events.bidWon, (e: IBidResponse) => {
      const slot = SlotRepository.getSlotById(e.adUnitCode)
      EventManager.trigger(EventManager.events.bidWon, e, slot)
    })

    pbjs.onEvent(EventManager.events.bidTimeout, (e) => {
      EventManager.trigger(EventManager.events.bidTimeout, e)
    })

    pbjs.onEvent(EventManager.events.auctionEnd, (e) => {
      EventManager.trigger(EventManager.events.auctionEnd, e)
    })

    return true
  }

  /** Helper for linking together a bid request to a bid response */
  initBidTie(): void {
    const bidRequests = {}
    EventManager.on(EventManager.events.bidRequested, ({ bids, start }) => {
      /* Create a bucket for bid requests going to a partner, based on timestamp */
      bidRequests[start] = bidRequests[start] || {}
      /* Cache the bid request */
      bids.forEach((bid) => (bidRequests[start][bid.bidId] = bid))
    })

    EventManager.on(EventManager.events.bidResponse, (bidResponse) => {
      /* Use timestamp as a lookup index. Link the original bid request. */
      // NOTE: Amazon does not return the requestId on the bidResponse
      const bidRequest =
        bidRequests[bidResponse.requestTimestamp]?.[bidResponse.requestId]
      bidResponse.bidRequest = bidRequest

      this.auctionHouse.addBids([bidResponse])
    })

    EventManager.on(EventManager.events.auctionEnd, ({ bidderRequests }) => {
      /* Clean up the bid request cache */
      bidderRequests.forEach((req) => delete bidRequests[req.start])
    })
  }

  /**
   * If you understand how these help us, please document it
   * below.
   * http://prebid.org/dev-docs/publisher-api-reference.html#module_pbjs.aliasBidder
   */
  private aliasBidders(pbjs: IPrebid): boolean {
    pbjs.aliasBidder('ix', 'indexExchange')
    return true
  }

  /**
   * Amazon is a bidder in our auctions. Amazon is not supported
   * natively by Prebid. By registering this custom adapter here,
   * Prebid will request a bid from Amazon during the normal auction
   * cycle.
   *
   * If the model isn't flagged to support UAM, the adapter is not registered.
   */
  private registerAdapters(model: WebModel, gdpr: IGDPR): boolean {
    if (model.uam) {
      this.registerAdapter(new AmazonAdapter(model, gdpr))
    }

    return true
  }

  static getTargeting(id: string): IAdServerTargeting {
    return window.pbjs.getAdserverTargetingForAdUnitCode(id)
  }
}

// Remove prebids whose `size` doesn't fit.
export function removeInvalidlySizedPrebids(
  prebidAdunit: IPrebidAdUnit,
  { sizes }: Pick<Slot, 'sizes'>
): IPrebidAdUnit {
  prebidAdunit.bids = prebidAdunit.bids.filter((bid) => {
    if (bid.isOutstream) {
      return true
    }
    if (prebidAdunit.mediaTypes.native) {
      return true
    }
    if (!prebidAdunit.mediaTypes.banner) {
      return
    }
    if (!bid.size) {
      return true
    }

    return sizes.find((allowedSize) => {
      return (
        !!bid.size &&
        allowedSize[0] === bid.size[0] &&
        allowedSize[1] === bid.size[1]
      )
    })
  })

  return prebidAdunit
}
